import { Box } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import DropDownCollapse from "./DropDownCollapse";
import DropDownHeader from "./DropDownHeader";
import DropDownList from "./DropDownList";
import { selectMobile } from "../../../redux/slices/sidebarSlice";

export default function DropDown() {
	const [open, setOpen] = useState(!useSelector(selectMobile));
	const [showContent, setShowContent] = useState(!useSelector(selectMobile));

	const switchOpen = () => {
		if (open) setShowContent(false);
		else {
			setTimeout(() => {
				setShowContent(true);
			}, 300);
		}
		setOpen((old) => {
			return !old;
		});
	};

	return (
		<Box
			className={open ? "opened" : "closed"}
			sx={{
				borderRadius: "10px",
				overflow: "hidden",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				backgroundColor: "white",
				width: open ? "260px" : "65px",
				height: open ? "380px" : "65px",
				transition: open
					? "width .3s, height .3s .3s, padding .6s"
					: "width .3s .3s, height .3s, padding .6s",
			}}
		>
			<DropDownHeader showContent={showContent} switchOpen={switchOpen} />
			<DropDownCollapse showContent={showContent} switchOpen={switchOpen} />

			<Box
				sx={{
					whiteSpace: "nowrap",
					overflow: "hidden",
					width: "100%",
					visibility: showContent ? "visible" : "hidden",
					padding: open ? "0 10px" : "0",
				}}
			>
				<DropDownList />
			</Box>
		</Box>
	);
}
