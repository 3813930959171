import { useEffect } from "react";
import 'leaflet.locatecontrol';
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css"; // Import styles
import { useMap } from "react-leaflet";
import * as L from 'leaflet';

// Extend the leaflet module
declare module 'leaflet' {
  namespace control {
    function locate(options?: any): any;
  }
}

export default function LocateControl() {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const locateOptions = {
      position: 'topright',
      initialZoomLevel: 10,
      maxZoom: 10,
      flyTo: true,
      onActivate: () => {}
    };

    const lc = L.control.locate(locateOptions); 
    lc.addTo(map);

    return () => {
      lc.remove();
    };

  }, [map]);

  return null;
}
