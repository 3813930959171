import gql from 'graphql-tag';
import { listUserWildfires, listUserWildfiresKML, listUserLightnings, listUserDeforestations } from "../../graphql/queries";
import { CognitoConfig } from '../wildfires/utils/CognitoConfig';

async function queryWildfires (polygonId, token = "") {
    let variables = { polygonId: polygonId, limit: 300 };
    if (token !== "") variables["nextToken"] = token;
    
    const { data: { listUserWildfires: { items, nextToken } } } = await CognitoConfig.query({
        query: gql(listUserWildfires),
        fetchPolicy: 'network-only',
        variables
    })
    return { items, nextToken }
}

async function queryLightnings (polygonId, token = "") {
    let variables = { polygonId: polygonId, limit: 300};
    if (token !== "") variables["nextToken"] = token;
    
    const { data: { listUserLightnings: { items, nextToken } } } = await CognitoConfig.query({
        query: gql(listUserLightnings),
        fetchPolicy: 'network-only',
        variables
    })
    
    return { items, nextToken }
}

async function queryDeforestation (polygonId, token = "") {
    let variables = { polygonId: polygonId, limit: 300};
    if (token !== "") variables["nextToken"] = token;

    const { data: { listUserDeforestations: { items, nextToken } } } = await CognitoConfig.query({
        query: gql(listUserDeforestations),
        fetchPolicy: 'network-only',
        variables
    })

    return { items, nextToken }
}

export async function get (polygonId, backwardsHours = 48, category = "W") {
    
    let wildfires = [];
    let jsonQuery = {nextToken: ""};

    while (typeof(jsonQuery["nextToken"]) === "string") {
        if (category === "L") jsonQuery = await queryLightnings(polygonId, jsonQuery["nextToken"]);
        else if (category === "W") jsonQuery = await queryWildfires(polygonId,jsonQuery["nextToken"])
        else if (category === "D") jsonQuery = await queryDeforestation(polygonId, jsonQuery["nextToken"]);
        for (const wildfire of jsonQuery["items"]) {
            const date = new Date(wildfire["date_id"].split("_")[0]);
            const diffTime = Math.abs(new Date().getTime() - date.getTime());
            const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
            if (diffHours <= backwardsHours) {
                wildfires.push(wildfire);
            }
            else {
                //if (category === "D") return new Array(20).fill(wildfire);
                return wildfires;
            }
        }
    }

    return wildfires;
}

export async function downloadKML (polygonId) {
    /* console.log(polygonId); */
    const { data: { listUserWildfiresKML: { kml } } } = await CognitoConfig.query({
        query: gql(listUserWildfiresKML),
        variables: {
            polygonId,
        }
    });
    return kml;
}

const exportedObject = {
    get,
    downloadKML
};

export default exportedObject;
