import { InfoOutlined, KeyboardArrowDown } from "@mui/icons-material";
import {
	IconButton, ListItem,
	ListItemButton, ListItemIcon, ListItemText
} from "@mui/material";
import { Box } from "@mui/system";
import ReactGA from "react-ga4";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import i18n from '../../../i18nextConf';
import { actions as modalActions } from "../../../redux/slices/modalsSlice";

export default function DropDownHeader({ showContent, switchOpen }) {
	const dispatch = useDispatch();
	const { t } = useTranslation()
	const sub = useSelector((state) => state.user?.userData?.sub);

	return (
		<ListItem
			disablePadding
			sx={{
				whiteSpace: "nowrap",
				overflow: "hidden",
				visibility: showContent ? "visible" : "hidden",
				".opened &": {
					maxWidth: "400px",
					maxHeight: "800px",
				},
				".closed &": { maxWidth: "0px", maxHeight: "0px" },
			}}
		>
			<ListItemButton
				role={undefined}
				onClick={switchOpen}
				sx={{
					".opened &": {
						padding: "inherit 10px",
					},
				}}
			>
				<ListItemText
					primary={
						<Box display="flex" flexDirection="row" alignItems="center">
							{t('filtro-focos-titulo-menu')}
							{i18n.language.includes("es") && (	
								<IconButton
								onClick={(e) => {
									e.stopPropagation();
									ReactGA.event({ category: 'Info Tipo de foco', action: sub })
									//dispatchhistory.push("/info")
									dispatch(modalActions.toggleInfoModal());
								}}
								>
								<InfoOutlined />
							</IconButton>
							)}
						</Box>
					}
					primaryTypographyProps={{
						style: {
							fontWeight: "600",
							fontSize: "1.2rem",
							color: "black"
						},
					}}
				/>
				<ListItemIcon sx={{ justifyContent: "flex-end" }}>
					<KeyboardArrowDown sx={{ color: "black" }} />
				</ListItemIcon>
			</ListItemButton>
		</ListItem>
	);
}
