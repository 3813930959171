import "../../scss/switchlayer.scss";
import SatelliteImage from "../../assets/layer-satelite.jpg";
import StreetImage from "../../assets/layer-calle.png";
import WindLayerImage from "../../assets/layer-wind.png";
import HumidityLayerImage from "../../assets/layer-rh.png";
import TempLayerImage from "../../assets/layer-temp.png";
//import PressureLayerImage from "../../assets/layer-pressure.png";

import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectLayer, actions } from '../../redux/slices/mapSlice';
import { useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Layers } from "@mui/icons-material";

const listLayers: {image: string, name: string}[] = [
  {
    name: "satellite-v9",
    image: SatelliteImage,
  },
  {
    name: "streets-v11",
    image: StreetImage,
  },
  {
    name: "windy-temp",
    image: TempLayerImage,
  },
  {
    name: "windy-rh",
    image: HumidityLayerImage,
  },
  {
    name: "windy-wind",
    image: WindLayerImage,
  }//,
  //{
  //  name: "windy-pressure",
  //  image: PressureLayerImage,
  //},
];

export default function SwitchLayerMap() {
  const dispatch = useAppDispatch();
  const layer = useAppSelector(selectLayer);
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const onLayerClick = (name: string) => {
    dispatch(actions.toggleLayerByName(name));
    setOpen(false);
    setShowContent(false);
  };

  const layerSelection = (
    <Stack direction="row" spacing={2}>
      {listLayers.map(({name, image}) => 
        <Stack direction="column" alignItems="center" spacing={1} width="64px">
          <img
            src={image}
            onClick={() => onLayerClick(name)}
            alt={"Imagen Capa " + name}
            className="imagen-layers"
            style={ layer === name ? {
              border: "2px solid #3E5AA9",
              padding: "2px",
            } : {}}
          />
          <Typography variant="subtitle2" fontSize="12px">
            {t("nombre-" + name)}
          </Typography>
        </Stack>
      )}
    </Stack>
  );

  return (
    <Box 
      sx={{
        borderRadius: "10px",
        backgroundColor: "white",
        padding: open ? "10px" : undefined,
        width: open ? "400px" : "65px",
        height: open ? undefined : "65px",
        transition: "width .3s",

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {showContent 
        ? layerSelection 
        : <IconButton 
          onClick={() => {
            setOpen(true);
            setTimeout(() => setShowContent(true), 300);
          }}
          sx={{
            padding: "15px",
            borderRadius: "10px",
            "& .MuiTouchRipple-root *, & .MuiTouchRipple-root, & .MuiTouchRipple-child":
              {
                borderRadius: "10px",
              },
          }}
        >
          <Layers fontSize="large" sx={{color: "#3E5AA9"}} />
        </IconButton>
      }
    </Box>
  );
}
