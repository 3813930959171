import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/polygons'
import mockPolygons from '../../api/polygons/mock'
import apiAlerts from '../../api/alerts'
import mockAlerts from '../../api/alerts/mock'
import swal from "sweetalert";
import bugsnag from '@bugsnag/js';
import i18n from '../../i18nextConf';

// import {history} from "../../services/prueba";

const initialState = {
	status: {
		mockState: -1,
		loading: false,
		alertsLoading: false,
		getAlertsStatus: 'idle',
		getPolygonStatus: 'idle',
		CRUDStatus: 'idle',
		createStatus: 'idle'
	}, // status: 'idle' | 'loading' | 'succeeded' | 'failed'
	error: null, // error: string | null
	polygons: {},
	alerts: [],
	rays: [],
	deforestations: [],
	cameras: [],
	wildfiresCameras: [],
};

// Alerts
export const getAlerts = createAsyncThunk('alerts/get', async ({ id, backwardHours }, thunkAPI) => {
	const state = thunkAPI.getState();
	if (!state.user.isLoggedIn) throw new Error("User is not logged in");
	try {
		if (state.polygons.status.mockState >= 0) return mockAlerts.getMockAlerts("W");
		const response = await apiAlerts.get(id, backwardHours, "W");
		return response;
	} catch (err) {
		swal(i18n.t("swal-error-cargando-alertas"), err.message, "error")
		throw err;
	}
});

export const getRays = createAsyncThunk('alerts/getRays', async ({ id, backwardHours }, thunkAPI) => {
	const state = thunkAPI.getState();
	if (!state.user.isLoggedIn) throw new Error("User is not logged in");
	try {
		if (state.polygons.status.mockState >= 0) return mockAlerts.getMockAlerts("L");
		const response = await apiAlerts.get(id, backwardHours, "L");
		return response;
	} catch (err) {
		swal(i18n.t("swal-error-cargando-alertas"), err.message, "error")
		throw err;
	}
});

export const getDeforestation = createAsyncThunk('alerts/getDeforestation', async ({ id, backwardHours }, thunkAPI) => {
	const state = thunkAPI.getState();
	if (!state.user.isLoggedIn) throw new Error("User is not logged in");
	try {
		if (state.polygons.status.mockState >= 0) return mockAlerts.getMockAlerts("D");
		const response = await apiAlerts.get(id, backwardHours, "D");
		return response;
	} catch (err) {
		swal(i18n.t("swal-error-cargando-alertas"), err.message, "error")
		throw err;
	}
});

export const deleteAlert = createAsyncThunk('alerts/delete', async ({ id }, thunkAPI) => {
	const state = thunkAPI.getState();
	if (!state.user.isLoggedIn) throw new Error("User is not logged in");

	const data = { id };
	try {
		if (state.polygons.status.mockState >= 0) return;
		const response = await apiAlerts.remove(data);
		swal(i18n.t('swal-eliminar-poligono-titulo'), i18n.t('swal-eliminar-poligono-texto'), "success")
		return response
	} catch (err) {
		swal(i18n.t('swal-error-eliminar-poligono-titulo'), err.message, "error")
		throw err;
	}
});

// Polygons
export const getPolygons = createAsyncThunk('polygons/get', async (args, thunkAPI) => {
	const state = thunkAPI.getState();
	if (!state.user.isLoggedIn) throw new Error("User is not logged in");

	try {
		if (state.polygons.status.mockState === 0) return [];
		else if (state.polygons.status.mockState > 0) return mockPolygons.getMockPolygons();

		const response = await api.get();
		return response
	} catch (err) {
		swal(i18n.t('swal-error-mostrando-poligono'), err.message, "error")
		throw err;
	}
});

export const createPolygon = createAsyncThunk('polygons/create', async ({ name, description, coords, detectWildfires, detectRays, detectDeforestation }, thunkAPI) => {
	const state = thunkAPI.getState();
	if (!state.user.isLoggedIn) throw new Error("User is not logged in");

	const data = { name, description, coords, detectRays, detectWildfires, detectDeforestation};
	try {
		if(state.polygons.status.mockState >= 0) return;
		if(state.polygons.polygons.length > 10) {
			swal(i18n.t('swal-limite-zonas-creadas-titulo'),i18n.t('swal-limite-zonas-creadas-texto'), "error");
			throw new Error("Has alcanzado el limite de zonas creadas");
		}
		const response = await api.post(data);
		swal(i18n.t('swal-zona-creada-titulo'), i18n.t('swal-zona-creada-texto'), "success")
		// history.push("/zonas")
		return response
	} catch (err) {
		swal(i18n.t('swal-error-creando-zona-titulo'), err.message, "error")
		throw err;
	}
});

export const updatePolygon = createAsyncThunk('polygons/update', async ({ id, name, description, coords, detectRays, detectWildfires, detectDeforestation}, thunkAPI) => {
	const state = thunkAPI.getState();
	if (!state.user.isLoggedIn) throw new Error("User is not logged in");
	
	//coords = JSON.stringify(coords);
	
	const data = { id, name, description, coords, detectRays, detectWildfires, detectDeforestation};
	try {
		if (state.polygons.status.mockState >= 0) return;
		const response = await api.put(data);
		swal(i18n.t('swal-editar-zona-titulo'), i18n.t('swal-editar-zona-texto'), "success")
		return response
	} catch (err) {
		swal(i18n.t('swal-error-editar-zona'), err.message, "error")
		throw err;
	}
});

export const deletePolygon = createAsyncThunk('polygons/delete', async ({ id }, thunkAPI) => {
	const state = thunkAPI.getState();
	if (!state.user.isLoggedIn) throw new Error("User is not logged in");
	const data = { id };
	try {
		if (state.polygons.status.mockState >= 0) return;
		const response = await api.remove(data);
		swal(i18n.t('swal-eliminar-poligono-titulo'), i18n.t('swal-eliminar-poligono-texto'), "success")
		return response
	} catch (err) {
		swal(i18n.t('swal-error-eliminar-poligono-titulo'), err.message, "error")
		throw err;
	}
});



const slice = createSlice({
	name: 'polygons',
	initialState,
	reducers: {
		clearStatus: (state) => {
			state.status.getAlertsStatus = 'idle';
			state.status.getPolygonStatus = 'idle';
			state.status.CRUDStatus = 'idle';
			state.status.createStatus = 'idle';
		},
		deleteAlerts: (state) => {
			state.alerts = [];
			state.wildfires = [];
			state.rays = [];
		},
		startMockModeNoPolygons: (state) => {
			state.status.mockState = 0;
		},
		startMockModeWithPolygons: (state) => {
			state.status.mockState = 1;
		},
		endMockMode: (state) => {
			state.status.mockState = -1;
		}
	},
	extraReducers: builder => {
		builder
			.addCase(getPolygons.fulfilled, (state, action) => {
				state.polygons = action.payload;
				state.status.getPolygonStatus = 'succeded';
				state.status.loading = false;
			})
			.addCase(createPolygon.fulfilled, (state, action) => {
				state.polygons.push(action.payload.data.createPolygon);
				state.status.createStatus = 'succeded';
				state.status.loading = false;
			})
			.addCase(updatePolygon.fulfilled, (state, action) => {
				const { id, name, description, coords, detectRays, detectWildfires} = action.payload.data.updatePolygon;
				state.polygons.forEach((polygon, index) => {
					if (polygon.id === action.payload.data.updatePolygon.id) state.polygons[index] = { id, name, description, coords, detectRays, detectWildfires};

				})
				state.status.CRUDStatus = 'succeded';
				state.status.loading = false;
			})
			.addCase(deletePolygon.fulfilled, (state, action) => {
				let idxToDelete = -1;
				state.polygons.forEach((polygon, index) => {
					if (polygon.id === action.payload.data.deletePolygon.id) idxToDelete = index;	
				});
				if (idxToDelete !== -1) state.polygons.splice(idxToDelete, 1);
				state.status.CRUDStatus = 'succeded';
				state.status.loading = false;
			})
			.addCase(getAlerts.fulfilled, (state, action) => {
				/* console.log(action) */
				state.alerts = action.payload;
				state.status.getAlertsStatus = 'succeded';
				state.status.alertsLoading = false;
			})
			.addCase(getRays.fulfilled, (state, action) => {
				/*console.log(action)*/
				state.rays = action.payload;
				state.status.getAlertsStatus = 'succeded';
				state.status.alertsLoading = false;
			})
			.addCase(getDeforestation.fulfilled, (state, action) => {
				/*console.log(action)*/
				state.deforestations = action.payload;
				state.status.getAlertsStatus = 'succeded';
				state.status.alertsLoading = false;
			})
			.addCase(deleteAlert.fulfilled, (state, action) => {
				delete state.alerts[action.payload.id];
				state.status = 'succeded';
				state.status.loading = false;
			})
			.addMatcher(
				action => action.type.endsWith('pending') && (action.type.startsWith("polygons")|| action.type.startsWith("alerts")),
				(state, action) => {
					if(action.type.startsWith("polygons")) state.status.loading = true;
					if(action.type.startsWith("alerts")) state.status.alertsLoading = true;
				}
			)
			.addMatcher(
				action => action.type.endsWith('rejected') && (action.type.startsWith("polygons")|| action.type.startsWith("alerts")),
				(state, action) => {
					//state.status = 'failed';
					bugsnag.notify(new Error(action.error.message));
					state.status.loading = false;
					state.error = action.error.message;
				}
			);

	},
});

export const selectPolygons = state => state.polygons.polygons;
export const selectError = state => state.polygons.error;
export const selectStatus = state => state.polygons.status;
export const selectAlerts = state => state.polygons.alerts;
export const selectWildfires = state => state.polygons.wildfires;
export const selectRays = state => state.polygons.rays;


export const actions = { ...slice.actions, getPolygons, createPolygon, updatePolygon, deletePolygon, getAlerts, getRays, getDeforestation, deleteAlert};

export default slice.reducer;