import React from "react";
import WindyMap, { WindyMapLayer } from "./BaseMapWindy";
import LeafletMap from "./BaseMapLeaflet";
import LogoSOF from "../../../assets/SOF_H_white_.svg";

interface MapSelectorProps {
  layer: string;
  center: [number, number];
  zoom: number;
  children: React.ReactNode;
}

function LogosContainer({withWindyLogo}: {withWindyLogo: boolean}) {
  return (
    <div id="windy-sof-logo-container">
      <a href="https://satellitesonfire.com">
        <img src={LogoSOF} width="250" alt="Logo Satellites On Fire" />
      </a>
      {withWindyLogo 
      ? <a href="https://www.windy.com/">
        <img 
          src="https://www.windy.com/img/logo201802/logo-full-windycom-white.svg" 
          width="150" 
          alt="Windy.com"
          id="windy-logo-img"
        />
      </a> : <></>}
    </div>
  );
}

export function MapSelector(props: MapSelectorProps) {
  if (props.layer.startsWith("windy")) {
    const windyLayer = props.layer.substring("windy-".length);
    return (
      <>
        <WindyMap
          center={props.center}
          zoom={props.zoom}
          style={{width: "100%", height: "100%"}}
          windyLayer={windyLayer as WindyMapLayer}
          >
          {props.children}
        </WindyMap>
        <LogosContainer withWindyLogo={true} />
      </>
    )
  } else {
    return (
      <>
        <LeafletMap
          layer={props.layer}
          center={props.center}
          zoom={props.zoom}
          >
          {props.children}
        </LeafletMap>
        <LogosContainer withWindyLogo={false} />  
      </>
    ) 
  }
}

export default MapSelector;