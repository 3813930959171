import gql from 'graphql-tag';
import { listPolygons } from "../../graphql/queries";
import { createPolygon, updatePolygon, deletePolygon } from "../../graphql/mutations";
import { CognitoConfig } from '../wildfires/utils/CognitoConfig';

async function get() {

    const { data: { listPolygons: { items } } } = await CognitoConfig.query({
        query: gql(listPolygons),
        limit: 10000,
        fetchPolicy: 'network-only'
        // https://www.apollographql.com/docs/react/data/queries/#setting-a-fetch-policy
    });

    items.forEach((poly)=>{
        if (Array.isArray(poly.coords)) return;
        else poly.coords = JSON.parse(poly.coords)
    })
    return items;
}

async function post(data) {
    const newTodo = await CognitoConfig.mutate({ mutation: gql(createPolygon), variables: { input: data } });
    if ('coords' in newTodo.data.createPolygon && !Array.isArray(newTodo.data.createPolygon.coords)) newTodo.data.createPolygon.coords = JSON.parse(newTodo.data.createPolygon.coords);
    return newTodo;
}

async function put(data) {
    const newTodo = await CognitoConfig.mutate({ mutation: gql(updatePolygon), variables: { input: data } });
    if ('coords' in newTodo.data.updatePolygon && !Array.isArray(newTodo.data.updatePolygon.coords)) newTodo.data.updatePolygon.coords = JSON.parse(newTodo.data.updatePolygon.coords);
    return newTodo;
}

async function remove(data) {
    const newTodo = await CognitoConfig.mutate({ mutation: gql(deletePolygon), variables: { input: data } });
    return newTodo;
}
const functions = {
    get,
    post,
    put,
    remove
};


export default functions;
//https://github.com/aws/serverless-application-model/issues/717