/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next'
import { Box } from "@mui/material";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as wildfireActions } from "../../../redux/slices/wildfireSlice";
import { actions as appbar } from "../../../redux/slices/appbarSlice";
import {
	actions as sidebarActions,
	selectCurrentMode,
} from "../../../redux/slices/sidebarSlice";
import { Check } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import DatesOverlay from "../Misc/DatesOverlay";


const actions = {
	1: wildfireActions.getLastTenMinutes,
	2: wildfireActions.getLastHour,
	3: wildfireActions.getLastThreeHours,
};
const times = {
	1: 20,
	2: 60,
	3: 180,
};

export default function TiempoReal() {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const [mode, setMode] = React.useState(useSelector(selectCurrentMode));
	const diff = times[mode];
	const startDate = new Date();
	startDate.setTime(startDate.getTime() - diff * 60 * 1000);
	const itemsContainerRef = useRef();

	useEffect(() => {
		dispatch(appbar.setTitle(t('tiempo-real')));
		dispatch(appbar.setDefaultActions());
	}, []);

	useEffect(() => {
		if (!itemsContainerRef.current) return;
		itemsContainerRef.current.childNodes.forEach((item, index) => {
			item.addEventListener("click", () => {
				dispatch(actions[index + 1]());
				setMode(index + 1);
				dispatch(sidebarActions.setCurrentMode(index + 1));
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemsContainerRef]);

	const items = [t('ultimos-20min'), t('ultima-hora'), t('ultimas-3horas')];
	
	return (
		<>
			<Box
				ref={itemsContainerRef}
				display="flex"
				flexDirection="row"
				gap="10px"
				sx={{
					backgroundColor: "white",
					padding: "5px 10px 10px 10px",
					width: "100%",
					whiteSpace: "nowrap",
					overflowX: "auto",
					boxSizing: "border-box",
					zIndex: 10,
					"::-webkit-scrollbar": {
						display: "none",
					},
				}}
			>
				{items.map((item, index) => {
					return (
						<Item
							key={`tiempo-real-${index}`}
							item={item}
							checked={mode === index + 1}
						/>
					);
				})}
			</Box>
			<DatesOverlay startDate={startDate} endDate={new Date()} />
		</>
	);
}

const Item = ({ item, checked }) => {
	const theme = useTheme();
	return (
		<Box
			display="flex"
			flexDirection="row"
			gap="5px"
			alignItems="center"
			sx={{
				padding: "8px 10px",
				border: checked
					? "1px solid " + theme.palette.primary.light
					: "1px solid #44464E",
				borderRadius: "6px",
				color: checked ? "#00164E" : "#44464E",
				bgcolor: checked ? "primary.light" : undefined,
				boxSizing: "border-box",
				height: "100%",
				fontWeight: "500",
			}}
		>
			{checked ? <Check color="inherit" /> : <></>}
			<span>{item}</span>
		</Box>
	);
};
