import { LocalFireDepartment } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React from "react";

export default function DropDownCollapse({ showContent, switchOpen }) {
	return (
		<Box
			sx={{
				backgroundColor: "white",
				display: showContent ? "none" : "initial",
			}}
		>
			<IconButton
				sx={{
					padding: "15px",
					borderRadius: "10px",
					"& .MuiTouchRipple-root *, & .MuiTouchRipple-root, & .MuiTouchRipple-child":
						{
							borderRadius: "10px",
						},
				}}
				onClick={switchOpen}
			>
				<LocalFireDepartment fontSize="large" sx={{ color: "#3E5AA9" }} />
			</IconButton>
		</Box>
	);
}
