import { MapWildfire } from "../redux/slices/types/wildfireTypes";
import {
  CLOUD_POLLUTED,
  HIGH_PROBABILITY,
  LOW_PROBABILITY,
  MEDIUM_PROBABILITY,
  MODIS,
  PROCESSED,
  SATURATED,
} from "../constants";
import { TypeOfSelectedFires } from "../redux/slices/types/sidebarTypes";

// Función para determinar la fiabilidad basada en MODIS.
function getReliabilityOnModis(
  item: MapWildfire,
  fireTypeLabel: string
): boolean {
  switch (fireTypeLabel) {
    case PROCESSED:
    case SATURATED:
    case HIGH_PROBABILITY:
      return item.cathegoryNum > 65 && item.cathegoryNum <= 100;
    case MEDIUM_PROBABILITY:
      return item.cathegoryNum > 35 && item.cathegoryNum <= 65;
    case LOW_PROBABILITY:
    case CLOUD_POLLUTED:
      return item.cathegoryNum >= 0 && item.cathegoryNum <= 35;
    default:
      return false;
  }
}

// Función para filtrar por valores específicos de fiabilidad dependiendo de los filtros seleccionados.
function filterByReliabilityValues(
  item: MapWildfire,
  values: number[]
): boolean {
  return values.includes(item.cathegoryNum);
}

// Función principal que se encarga de filtrar los puntos de incendio según los filtros seleccionados.
export const filterWildfires = (
  wildfires: MapWildfire[],
  fireTypeFilters: TypeOfSelectedFires
): MapWildfire[] => {
  return wildfires.filter((item) => {
    for (let fireType of fireTypeFilters) {
      if (
        !fireType.active &&
        ((item.satellite.includes(MODIS) &&
          getReliabilityOnModis(item, fireType.label)) ||
          filterByReliabilityValues(item, fireType.values))
      ) {
        return false;
      }
    }
    return true;
  });
};
