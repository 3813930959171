import { getPublicWildfireByDate } from "../../graphql/queries";
import { ApiKeyConfig } from './utils/ApiKeyConfig';
import gql from 'graphql-tag';

async function queryWildfiresByDate (UTCDate, token = "") {
	let variables = {
		date: UTCDate,
		limit: 100000,
		filter: { conf: { ne: 101 } },
	}

	if (token !== "") variables["nextToken"] = token;

	const { data: { getPublicWildfireByDate: { items, nextToken } } } = await ApiKeyConfig.query({
		query: gql(getPublicWildfireByDate),
		variables,
		fetchPolicy: "no-cache",
	});

	return { items, nextToken };
}

export async function getByDate(date /*:Date object*/) {
	const strQuery = date.toISOString().slice(0, 13); // Convierte a UTC, y extrae el substring que nos interesa
	let wildfires = [];

	let jsonQuery = await queryWildfiresByDate(strQuery);
	wildfires = wildfires.concat(jsonQuery["items"]);

	while (jsonQuery["nextToken"]) {
		jsonQuery = await queryWildfiresByDate(strQuery, jsonQuery["nextToken"]);
		wildfires = wildfires.concat(jsonQuery["items"]);
	}

	return wildfires;
}

export default getByDate;