import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import PropTypes from "prop-types";

function NoAlerts(props) {
    const wildfires = useSelector(state => state.wildfires.wildfires);
    const path = useHistory().location.pathname;
    const dataSource = useSelector(state => state.wildfires.source);

    return ( 
        dataSource !== null && dataSource !== undefined 
        ? <NoAlertsComponent
            wildfires={wildfires}
            path={path}
            dataSource={dataSource}
            {...props}
        /> : <></>
    )
}

function NoAlertsComponent({wildfires, path, dataSource}) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (wildfires.length > 0) return;
        if (!['getLastTenMinutes', 'getLastHour', 'getLastThreeHours'].includes(dataSource)) return;
        if (!(path === '/' || path === '/focos-historicos')) return;
        setOpen(true);

    }, [wildfires, path, dataSource]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpen(false);
    };

    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleClose}
        >
            <Alert severity="warning" onClose={handleClose}>
                Las imágenes del satélite se han retrasado, por lo que no se han podido cargado focos de calor.
                Recomendamos revisar información de horas anteriores.
            </Alert>
        </Snackbar>
    )
}

NoAlertsComponent.propTypes = {
    wildfires: PropTypes.array.isRequired,
    path: PropTypes.string.isRequired,
    dataSource: PropTypes.string.isRequired,
}

export default NoAlerts;