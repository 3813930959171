/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPolygon = /* GraphQL */ `
  mutation CreatePolygon($input: CreatePolygonInput!) {
    createPolygon(input: $input) {
      id
      name
      description
      coords
      detectRays
      detectWildfires
      detectDeforestation
      lastModificationDate
    }
  }
`;
export const updatePolygon = /* GraphQL */ `
  mutation UpdatePolygon($input: UpdatePolygonInput!) {
    updatePolygon(input: $input) {
      id
      name
      description
      coords
      detectRays
      detectWildfires
      detectDeforestation
      lastModificationDate
    }
  }
`;
export const deletePolygon = /* GraphQL */ `
  mutation DeletePolygon($input: DeletePolygonInput!) {
    deletePolygon(input: $input) {
      id
      name
      description
      coords
      detectRays
      detectWildfires
      detectDeforestation
      lastModificationDate
    }
  }
`;
export const updateCamera = /* GraphQL */ `
  mutation UpdateCamera($input: UpdateCameraInput!) {
    updateCamera(input: $input) {
      name
      cameraId
      cameraUrl
      cgiPort
      ip
      user
      password
      x
      y
      elevation
      sensorHeight
      sensorWidth
      movementType
      lastDetectionDate
    }
  }
`;
export const createNotificationEndpoint = /* GraphQL */ `
  mutation CreateNotificationEndpoint(
    $input: CreateNotificationEndpointInput!
  ) {
    createNotificationEndpoint(input: $input) {
      endpointId
      endpointName
      endpoint
    }
  }
`;
export const updateNotificationEndpoint = /* GraphQL */ `
  mutation UpdateNotificationEndpoint(
    $input: UpdateNotificationEndpointInput!
  ) {
    updateNotificationEndpoint(input: $input) {
      endpointId
      endpointName
      endpoint
    }
  }
`;
export const deleteNotificationEndpoint = /* GraphQL */ `
  mutation DeleteNotificationEndpoint(
    $input: DeleteNotificationEndpointInput!
  ) {
    deleteNotificationEndpoint(input: $input) {
      endpointId
      endpointName
      endpoint
    }
  }
`;
export const updateUserConfig = /* GraphQL */ `
  mutation UpdateUserConfig($input: UpdateUserConfigInput) {
    updateUserConfig(input: $input) {
      name
      email
      country
      phone
      organizationName
      organizationType
      freeTrial
      language
      notifyByEmail
      notifyByPush
      notifyByPhone
      userType
      userTypeTill
      defaultLayer
      notifyMinuteGap
      typeOfAlert
    }
  }
`;
export const startFreeTrial = /* GraphQL */ `
  mutation StartFreeTrial($email: String!) {
    startFreeTrial(email: $email) {
      status
    }
  }
`;
export const redeemPromoCode = /* GraphQL */ `
  mutation RedeemPromoCode($promoCode: String!) {
    redeemPromoCode(promoCode: $promoCode) {
      status
    }
  }
`;
export const createZoneKML = /* GraphQL */ `
  mutation CreateZoneKML($kml: String!) {
    createZoneKML(kml: $kml) {
      status
    }
  }
`;
export const updateCameraEvent = /* GraphQL */ `
  mutation UpdateCameraEvent($input: UpdateCameraEventInput) {
    updateCameraEvent(input: $input) {
      date_id
      bbox
      classNumber
      confidence
      confirmWildfire
      hasBeenDetectedByHuman
      x
      y
    }
  }
`;
export const createWildfiresReview = /* GraphQL */ `
  mutation CreateWildfiresReview($input: CreateWildfiresReviewInput!) {
    createWildfiresReview(input: $input) {
      user_id
      wildfire_id
      wildfire_date
      result
      origin
      review_date
      user_description
      source
      cathegory
      x
      y
    }
  }
`;
