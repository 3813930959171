import { getPublicWildfireByDate } from "../../graphql/queries";
import gql from "graphql-tag";
import { ApiKeyConfig } from "./utils/ApiKeyConfig";
//import { strike } from "aws-amplify";

async function queryWildfires(minutesBefore, wildfiresDate, token="") {

	const geDate = new Date();
	geDate.setMinutes(geDate.getMinutes() - minutesBefore);
	const geString = geDate.toISOString().slice(0,16);

	let variables = {
		date: wildfiresDate,
		idGtThan: geString,
		limit: 100000,
		filter: {conf: {ne:101}}
	}
	
	if (minutesBefore < 30) delete variables["filter"];
	if (token !== "") variables["nextToken"] = token;
	
	const { data: { getPublicWildfireByDate: { items, nextToken }}} = await ApiKeyConfig.query({
		query: gql(getPublicWildfireByDate),
		variables
	});

	return {items, nextToken};
}

export async function fetchWildfires (minutesBefore = 15) {
	let from = new Date();
	let to = new Date();
	
	from.setMinutes(from.getMinutes() - minutesBefore);
	let wildfires = [];

	for (let i = from.getHours(); i !== (to.getHours() + 1) % 24; i = (i + 1) % 24) {
		
		const dateTemp = new Date();
		dateTemp.setHours(i);
		const str = dateTemp.toISOString().slice(0,13);

		let jsonQuery = await queryWildfires(minutesBefore, str);
		wildfires = wildfires.concat(jsonQuery["items"]);

		while (jsonQuery["nextToken"]) {
			jsonQuery = await queryWildfires(minutesBefore, str, jsonQuery["nextToken"]);
			wildfires = wildfires.concat(jsonQuery["items"]);
		}
	}
	/* console.log(wildfires.length); */
	return wildfires;
}