import React from 'react';
import { GoogleLayer } from 'react-leaflet-google-v2';
import { MapContainer, LayersControl } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { selectMobile } from '../../../redux/slices/sidebarSlice';

interface BaseMapLeafletProps {
  layer: string;
  center: [number, number];
  zoom: number;
  children: React.ReactNode;
}

function BaseMapLeaflet(props: BaseMapLeafletProps) {
  const isMobile = useSelector(selectMobile);
  const { BaseLayer } = LayersControl;

  React.useEffect(() => {
    const leafletStyles = document.createElement('link');
    leafletStyles.rel = 'stylesheet';
    leafletStyles.href = '/leaflet/leaflet.css';
    document.head.appendChild(leafletStyles);

    return () => {
      document.head.removeChild(leafletStyles);
    };
  }, []);

  return (
    <MapContainer
      style={{ width: (isMobile ? '100%' : '100vw'), height: (isMobile ? '100%' : '100vh'), zIndex: 0 }}
      maxBounds={[[-90, -180], [90, 180]]}
      center={props.center}
      zoom={props.zoom}
      zoomAnimation={true}
      zoomSnap={1}
      zoomControl={false}
      minZoom={2}
      preferCanvas={true}
      zoomAnimationThreshold={10}
      markerZoomAnimation={true}
      wheelDebounceTime={100}
      wheelPxPerZoomLevel={30}
    >
      <LayersControl>
        <BaseLayer checked={props.layer === "streets-v11"} name="Capa Político">
          <GoogleLayer googlekey={process.env.REACT_APP_GOOGLE_MAPS_TOKEN} maptype='TERRAIN' />
        </BaseLayer>
        <BaseLayer checked={props.layer === "satellite-v9"} name="Capa Satelital">
          <GoogleLayer googlekey={process.env.REACT_APP_GOOGLE_MAPS_TOKEN} maptype='SATELLITE' />
        </BaseLayer>
      </LayersControl>
      {props.children}
    </MapContainer>
  );
};

export default BaseMapLeaflet;