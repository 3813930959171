import {
	DeleteOutlined, Done, EditOutlined,
	LayersOutlined
} from "@mui/icons-material";
import {
	Box,
	createSvgIcon,
	Divider,
	Slide,
	styled,
	TextField,
	Typography,
	FormControlLabel,
	Checkbox,
	Tooltip
} from "@mui/material";
import MuiFab from "@mui/material/Fab";
import PropTypes from 'prop-types';
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { actions as mapActions } from "../../../redux/slices/mapSlice";
import { actions as polygonActions } from "../../../redux/slices/polygonsSlice";
import area from "@turf/area";
import LeafletMap from "../../mapa/LeafletMap";
import { Button } from "../../StyledComponents/Button";
import {
	Dialog,
	DialogContent,
	DialogTitle
} from "../../StyledComponents/Dialog";
import { actions as sidebarActions } from "../../../redux/slices/sidebarSlice";
import TourResumer from "../../utils/TourResumer";

const MAX_DEFORESTATION_AREA = 50000;

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function NuevaZona({ open, close }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [featureCoords, setFeatureCoords] = React.useState();
	const [datos, setDatos] = React.useState({
		nombreZona: "",
		description: "",
		coords: "",
		detectWildfires: false,
		detectRays: false,
		detectDeforestation: false,
	});
	const [error, setError] = useState(false);
	const [estimatedArea, setEstimatedArea] = useState(0);
	const [drawing, setDrawing] = useState(false);
	const [editing, setEditing] = useState(false);
	const [drawControl, setDrawControl] = useState();

	const draw = () => {
		drawControl._toolbars.draw._modes.polygon.handler.enable();
		setDrawing(true);
	};

	const finishDraw = () => {
		drawControl._toolbars.draw._modes.polygon.handler._finishShape();
		if (!drawControl._toolbars.draw._modes.polygon.handler.enabled())
			setDrawing(false);
	};

	const deleteDraw = () => {
		if (!drawControl) return;
		if (drawControl._toolbars.draw._modes.polygon.handler.enabled())
			drawControl._toolbars.draw._modes.polygon.handler.disable();
		try {
			drawControl._toolbars.edit._modes.remove.handler.removeAllLayers();
		} catch (e) { }
		setDrawing(false);
		setEditing(false);
		setFeatureCoords();
	};

	const editDraw = () => {
		if (
			drawControl._toolbars.draw._modes.polygon.handler.enabled() ||
			!featureCoords
		)
			return;
		drawControl._toolbars.edit._modes.edit.handler.enable();
		setEditing(true);
	};

	const saveEdit = () => {
		drawControl._toolbars.edit._modes.edit.handler.save();
		drawControl._toolbars.edit._modes.edit.handler.disable();
		setEditing(false);
	};

	const changeLayer = () => {
		dispatch(mapActions.toggleLayerLeaflet());
	};

	const handleInputChange = (e) => {
		setDatos({
			...datos,
			[e.target.name]: e.target.value,
		});
	};

	const handleTypeOfAlertsChange = (e) => {
		setDatos({
			...datos,
			[e.target.name]: e.target.checked,
		});
	}

	const onClose = () => {

		if (drawControl) {
			if (drawControl._toolbars.draw._modes.polygon.handler.enabled())
				drawControl._toolbars.draw._modes.polygon.handler.disable();
			try {
				drawControl._toolbars.edit._modes.remove.handler.removeAllLayers();
			} catch (e) { }
			setDrawing(false);
			setEditing(false);
			setFeatureCoords(undefined);
		}
		close();
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		let coordinates;
		if (!datos.detectWildfires && !datos.detectRays && !datos.detectDeforestation) {
			swal(
				t("swal-error-no-seleccionaste-alertas-titulo"),
				t("swal-error-no-seleccionaste-alertas-texto"),
				"info"
			);
			return;
		}
		if (featureCoords) {
			coordinates = featureCoords.map((item) =>
				item.map((elem) => [elem.lat, elem.lng])
			);
			coordinates = coordinates[0];
		} else coordinates = datos.coords;

		if (featureCoords === undefined)
			swal(
				t('swal-error-no-dibujaste-poligono-titulo'),
				t('swal-error-no-dibujaste-poligono-texto'),
				"info"
			);
		else if (coordinates.length > 15) {
			swal(
				t("swal-no-crear-poligono-vertices-titulo"),
				"swal-no-crear-poligono-vertices-texto",
				"info"
			);
		}
		else {
			close();
			
			const sendData = {
				name: datos.nombreZona,
				description: datos.description,
				coords: JSON.stringify(coordinates),
				detectWildfires: datos.detectWildfires,
				detectRays: datos.detectRays,
				detectDeforestation: datos.detectDeforestation
			};
			
			dispatch(polygonActions.createPolygon(sendData));
		}
		setFeatureCoords(undefined);
	};

	React.useEffect(() => {
		if (open) dispatch(sidebarActions.setRun(true))
	}, [open, dispatch]);

	const calculatePolygon = (featureCoords) => {
		if (!featureCoords) return 0;

		const geoJsonPolygon = {
			type: "Polygon",
			coordinates: [
				featureCoords[0].map(point => [point.lng, point.lat])
			]
		};
		var polygonAreaInKM2 = area(geoJsonPolygon) / 1000000;

		return polygonAreaInKM2;
	}

	React.useEffect(() => {
		const result = calculatePolygon(featureCoords);
		setEstimatedArea(result);
		if (featureCoords && datos.detectDeforestation) {
			if (result > MAX_DEFORESTATION_AREA) {
				setError(true);
			}
		} else {
			setError(false);
		}
	}, [featureCoords, datos]);

	return (
		<>
			{open ? <TourResumer wakeUpSteps={[1]} /> : <></>}
			<Dialog
				open={open}
				onClose={onClose}
				fullWidth
				scroll="body"
				TransitionComponent={Transition}
			>
				<form onSubmit={handleSubmit}>
					<DialogTitle>{t("titulo-crear-zona")}</DialogTitle>
					<Divider />
					<DialogContent>
						<Typography
							variant="span"
							color="#707176"
							fontSize=".9rem"
							sx={{ marginTop: "20px" }}
						>
							{t("typography-crear-zona")}
						</Typography>
						<Box
							display="flex"
							flexDirection="column"
							gap="10px"
							sx={{ padding: "0 10px" }}
							id="tour-1"
						>
							<TextField
								name="nombreZona"
								label={t("nombre-de-la-zona")}
								variant="standard"
								required
								onChange={handleInputChange}

							/>
							<TextField
								name="description"
								label={t("descripcion-de-la-zona")}
								variant="standard"
								onChange={handleInputChange}
							/>
						</Box>
						<Box display="flex" flexDirection="column" gap="5px" marginTop="20px" marginBottom="10px" id="tour-2">

							<Typography
								variant="span"
								color="#707176"
								fontSize=".9rem"
							>
								{t('typography-focos-rayos')}
							</Typography>
							<Box
								display="flex"
								justifyContent="space-between"
								//gridTemplateColumns="repeat(auto-fill, minmax(210px, 1fr))"
								width="60%"
								alignSelf="center"
							>
								<FormControlLabel
									name="detectWildfires"
									control={
										<Checkbox
											style={{
												color: "#B71C1C",
											}}
											edge="start"
											checked={datos.detectWildfires}
											disableRipple
											onChange={(e) =>
												handleTypeOfAlertsChange(e)
											}
										/>
									}
									label={t('focos-de-calor')}
									labelPlacement="end"
									sx={{ margin: 0 }}
								/>
								<FormControlLabel
									name="detectRays"
									control={
										<Checkbox
											style={{
												color: "#4264FB",
											}}
											edge="start"
											checked={datos.detectRays}
											disableRipple
											onChange={(e) =>
												handleTypeOfAlertsChange(e)
											}
										/>
									}
									label={t('focos-de-rayos')}
									labelPlacement="end"
									sx={{ margin: 0 }}
								/>
								  <Tooltip
									PopperProps={{
										disablePortal: true,
									}}
									open={error}
									disableFocusListener
									disableHoverListener
									disableTouchListener
									title={t("error-poligono-deforestacion", {MAX_VALUE: MAX_DEFORESTATION_AREA})}
									arrow
								>
									<FormControlLabel
										name="detectDeforestation"
										control={
											<Checkbox
												style={{
													color: "#039048",
												}}
												edge="start"
												checked={datos.detectDeforestation}
												disableRipple
												onChange={(e) =>
													handleTypeOfAlertsChange(e)
												}
											/>
										}
										label={t('focos-de-deforestacion')}
										labelPlacement="end"
										sx={{ margin: 0 }}
									/>
								</Tooltip>
							</Box>
						</Box>

						<Box
							width="100%"
							position="relative"
							sx={{
								aspectRatio: "16 / 9",
								borderRadius: "10px",
								overflow: "hidden",
							}}
							id="tour-3"
						>
							<LeafletMap
								featureCoords={datos.coords}
								setFeatureCoords={setFeatureCoords}
								edit={false}
								onCreate={() => setDrawing(false)}
								onEdit={() => setEditing(false)}
								setDrawControl={setDrawControl}
							/>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="flex-end"
								gap="10px"
								position="absolute"
								zIndex="9"
								sx={{
									right: "10px",
									bottom: "15px",
									"& .MuiSvgIcon-root": { animation: "iconChange .3s" },
								}}
							>
								<Fab size="small" onClick={changeLayer}>
									<LayersOutlined />
								</Fab>
								<Fab size="small" onClick={deleteDraw}>
									<DeleteOutlined />
								</Fab>
								{/* <Fab size="small" onClick={editing ? saveEdit : editDraw}>
									{editing ? <Done /> : <Eraser />}
								</Fab> */}

								<Fab
									size="medium"
									onClick={
										featureCoords
											? editing
												? saveEdit
												: editDraw
											: drawing
												? finishDraw
												: draw
									}
									id="tour-4"
								>
									{featureCoords ? (
										editing ? (
											<Done />
										) : (
											<EditOutlined />
										)
									) : drawing ? (
										<Done />
									) : (
										<Polygon />
									)}
								</Fab>
							</Box>
						</Box>
						<Box
							display="flex"
							flexDirection="row"
							gap="10px"
							sx={{ justifyContent: "space-between", marginTop: "20px" }}
						>
							<Box>
								<Typography color="#707176" fontSize=".9rem">{t("superficie-estimada")}: {estimatedArea ? Math.floor(estimatedArea * 100) / 100 : 0} km2</Typography>
							</Box>
							<Box>
								<Button variant="text" onClick={close}>
									{t("boton-cancelar")}
								</Button>

								{drawing === false && !error && (
									<Button
										variant="contained"
										type="submit" /* onClick={handleSubmit} */
										id="tour-5"
									>
										{t("boton-crear-zona")}
									</Button>
								)}
							</Box>
						</Box>
					</DialogContent>
				</form>
			</Dialog>
		</>
	);
}

NuevaZona.propTypes = {
	open: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
};

const Polygon = createSvgIcon(
	<path d="M17,15.7V13H19V17L10,21L3,14L7,5H11V7H8.3L5.4,13.6L10.4,18.6L17,15.7M22,5V7H19V10H17V7H14V5H17V2H19V5H22Z" />,
	"Polygon"
);

const Fab = styled(MuiFab)(({ theme }) => ({
	boxShadow: "none",
	borderRadius: "12px",
	backgroundColor: theme.palette.primary.light,
	"&.MuiFab-sizeMedium": {
		height: "46px",
		width: "46px",
		"& .MuiSvgIcon-root": {
			height: "25px",
			width: "25px",
		},
	},
	"&.MuiFab-sizeSmall": {
		height: "36px",
		width: "36px",
		"& .MuiSvgIcon-root": {
			height: "20px",
			width: "20px",
		},
	},
}));
