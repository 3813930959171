import {
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Checkbox,
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { filtrosDeFoco } from "../../utils/Utils";
import { actions } from "../../../redux/slices/sidebarSlice";

export default function DropDownList() {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const selectedFires = useSelector((state) => state.sidebar.selectedFires);
	function handleToggle(value) {
		let temp = [...selectedFires];
		temp[value] = !temp[value];
		dispatch(actions.selectFireType(temp));
	}

	return (
		<List>
			{filtrosDeFoco.filter((item, i) => i < 6).map((item, i) => {
				const labelId = `foco ${item.label}`;

				return (
					<ListItem key={labelId} disablePadding>
						<ListItemButton
							role={undefined}
							onClick={() => handleToggle(item.index)}
							dense
							sx={{ gap: "10px" }}
						>
							<ListItemIcon sx={{ minWidth: "fit-content" }}>
								<Checkbox
									style={{
										color: item.color,
									}}
									edge="start"
									checked={selectedFires[item.index]}
									disableRipple
								/>
							</ListItemIcon>
							<ListItemText
								id={labelId}
								primary={t(item.label)}
								//primary={t('')}
								sx={{ "& .MuiTypography-root": { fontWeight: 700, color: "#49454F" } }}
							/>
						</ListItemButton>
					</ListItem>
				);
			})}
		</List>
	);
}
